<template>
  <div class="userManagement_JH" >
    <div>
      <div
        class="fontSize fontWeight color"
        style="
          text-align: center;
          padding: 1vh 0;
          border-bottom: 1px solid #ccc;
          margin-bottom: 1vh;
        "
      >
        权限设置
      </div>
      <div style="padding: 0 2vw; box-sizing: border-box">
        <div style="margin-bottom: 1vh">
          角色：
          <el-select
            v-model="roleId"
            placeholder="请选择"
            size="medium"
            class="marginRight"
          >
            <el-option
              v-for="item in roleList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          姓名：
          <el-input
            clearable
            v-model="name"
            style="max-width: 220px"
            class="marginRight"
            placeholder="请输入姓名关键词"
            >></el-input
          >

          <span class="marginRight pointer color" @click="getDataList"
            >搜索</span
          >
          <span class="marginRight pointer color" @click="rest">重置</span>
          <span class="pointer color" @click="syncAddressList">同步通讯录</span>
        </div>
        <el-table
          v-loading="loading"
          :data="dataList"
          style="max-height: 700px"
        >
          <el-table-column
            prop="name"
            label="姓名"
            align="center"
            fixed
          ></el-table-column>
          <el-table-column
            prop="departmentName"
            label="部门"
            align="center"
          ></el-table-column>
<!--          <el-table-column-->
<!--            prop="reportTime"-->
<!--            label="职务"-->
<!--            align="center"-->
<!--          ></el-table-column>-->
<!--          <el-table-column-->
<!--            prop="type1"-->
<!--            label="账号"-->
<!--            align="center"-->
<!--          ></el-table-column>-->
          <el-table-column
            prop="userId"
            label="openid"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="mobile"
            label="手机号"
            align="center"
          ></el-table-column>
<!--          <el-table-column-->
<!--            prop="time"-->
<!--            label="邮箱"-->
<!--            align="center"-->
<!--          ></el-table-column>-->
          <el-table-column prop="roleId" label="角色" align="center">
            <span slot-scope="scope">{{
              scope.row.roleId == 0
                ? "未设置"
                : scope.row.roleId == 1
                ? "用户"
                : "管理员"
            }}</span>
          </el-table-column>

          <el-table-column label="操作" align="center">
            <span
              slot-scope="scope"
              class="operator color pointer"
              @click="setting(scope.row)"
            >
              权限设置
            </span>
          </el-table-column>
        </el-table>
        <div class="pagination_JH">
          <el-pagination
            background
            @size-change="handleSizeChange('pageSize', $event)"
            @current-change="handleSizeChange('page', $event)"
            :current-page.sync="page"
            :page-sizes="[10, 30, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <authSettingDiaLog
      :dialogShow.sync="dialogShow"
      :item="item"
      :orgId="orgId"
      @change="editHandle"
    ></authSettingDiaLog>
  </div>
</template>

<script>
import { getUserList } from "@/api/api.js";
import authSettingDiaLog from "./authSettingDiaLog";
import { getUserRest } from "../../../api/api";
export default {
  name: "userManagement",
  components: {
    authSettingDiaLog,
  },
  props: {
    orgId: {
      required: true,
    },
  },
  watch: {
    orgId() {
      this.getDataList();
    },
  },
  data() {
    return {
      userInfo:'',
      item: {},
      defaultProps: {
        children: "children",
        label: "label",
      },
      roleList: [
        { value: "-1", label: "不限" },
        { value: "0", label: "未设置" },
        { value: "1", label: "用户" },
        { value: "2", label: "管理员" },
      ],
      roleId: "-1",
      name: "",
      loading: false,
      dataList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      dialogShow: false,
    };
  },
  created() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.userInfo = userInfo
    console.log(this.orgId);
    this.getDataList();
  },
  methods: {
    editHandle(val) {
    },
    setting(val) {
      this.item = val;
      this.dialogShow = true;
    },
    handleNodeClick() {},
    rest() {
      this.roleId = "-1";
      this.name = "";
    },
    async syncAddressList() {
      this.rest();
      this.page = 1;
      if(this.userInfo && this.$store.state.isWxWork){
        await getUserRest({
          orgId: this.orgId ,
          userIdNew:this.userInfo.userId
        });
      }else{
        await getUserRest({ orgId: this.orgId });
      }

      await this.getDataList();
    },
    handleSizeChange(type, val) {
      if (type == "page") {
        this.page = val;
      } else {
        this.pageSize = val;
      }
      this.getDataList();
    },
    async getDataList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      var body
      if(this.userInfo && this.$store.state.isWxWork){
         body = {
          name: this.name,
          page: this.page,
          pageSize: this.pageSize,
          orgId: this.orgId,
          userIdNew:this.userInfo.userId
        };
      }else{
        body = {
          name: this.name,
          page: this.page,
          pageSize: this.pageSize,
          orgId: this.orgId,
        }
      }

      if (this.roleId != -1) {
        body.roleId = Number(this.roleId);
      }
      const datas = await getUserList(body);
      const data = JSON.parse(datas);

      this.total = data.total;
      this.dataList = data.list;
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="less">
.userManagement_JH {
  width: 100%;
  height: 96%;
  overflow: hidden;
  overflow-y: auto;
  > :first-child {
    margin-bottom: 0.5rem;
  }
  > :nth-child(2) {
    display: grid;
    //grid-template-columns:1fr 10fr;

    > div {
      border-top: 1px solid #ccc;
      height: 100%;
      padding: 1rem;
    }

    //> :first-child {
    //  border-right: 1px solid #ccc;
    //}
  }
}
</style>
