<template>
  <div class="userManagement_JH"
       v-touch:swiperight="rightSlide"
  >
    <wxWorkUserManagement :org-id="orgId"></wxWorkUserManagement>
  </div>
</template>

<script>
import wxWorkUserManagement from "./children/wxWorkUserManagement";
import { getSignature } from "@/api/api";
export default {
  name: "index",
  components: {
    wxWorkUserManagement,
  },
  props: {},
  data() {
    return {
      orgId: "",
      show: false,
    };
  },
  directives: {
    //滑动指令
    touch: {
      bind: function (el, binding, vnode) {
        var touchType = binding.arg; //传入的模式 press swipeRight swipeLeft swipeTop swipeDowm Tap
        var timeOutEvent = 0;
        var direction = "";
        //滑动处理
        var startX, startY;

        //返回角度
        function GetSlideAngle(dx, dy) {
          return (Math.atan2(dy, dx) * 180) / Math.PI;
        }

        //根据起点和终点返回方向 1：向上，2：向下，3：向左，4：向右,0：未滑动
        function GetSlideDirection(startX, startY, endX, endY) {
          var dy = startY - endY;
          var dx = endX - startX;
          var result = 0;

          //如果滑动距离太短
          if (Math.abs(dx) < 180 && Math.abs(dy) < 180) {
            return result;
          }

          var angle = GetSlideAngle(dx, dy);
          if (angle >= -45 && angle < 45) {
            result = "swiperight";
          } else if (angle >= 45 && angle < 135) {
            result = "swipeup";
          } else if (angle >= -135 && angle < -45) {
            result = "swipedown";
          } else if (
            (angle >= 135 && angle <= 180) ||
            (angle >= -180 && angle < -135)
          ) {
            result = "swipeleft";
          }
          return result;
        }

        el.addEventListener(
          "touchstart",
          function (ev) {
            startX = ev.touches[0].pageX;
            startY = ev.touches[0].pageY;

            //判断长按
            timeOutEvent = setTimeout(() => {
              timeOutEvent = 0;
              if (touchType === "press") {
                binding.value();
              }
            }, 500);
          },
          false
        );

        el.addEventListener("touchmove", function (ev) {
          clearTimeout(timeOutEvent);
          timeOutEvent = 0;
        });

        el.addEventListener(
          "touchend",
          function (ev) {
            var endX, endY;
            endX = ev.changedTouches[0].pageX;
            endY = ev.changedTouches[0].pageY;
            direction = GetSlideDirection(startX, startY, endX, endY);

            clearTimeout(timeOutEvent);

            switch (direction) {
              case 0:
                break;
              case "swipeup":
                if (touchType === "swipeup") {
                  binding.value();
                }
                break;
              case "swipedown":
                if (touchType === "swipedown") {
                  binding.value();
                }
                break;
              case "swipeleft":
                if (touchType === "swipeleft") {
                  binding.value();
                }
                break;
              case "swiperight":
                if (touchType === "swiperight") {
                  binding.value();
                }
                break;
              default:
            }
          },
          false
        );
      },
    },
  },
  methods: {
    rightSlide() {
      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (JSON.parse(sessionStorage.getItem("from")) == "/") {
        window.wx.closeWindow();
      }
    },
    async getLoginMsg(agentid, appid) {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (userInfo) {
        if (userInfo.orgId.includes(this.orgId) && userInfo.roleIds == 2) {
          this.show = true;
          await this.getSignature(agentid, appid);
        } else {
          this.$router.push({ path: "/noPermissions" });
        }
      } else {
        this.$router.push({ path: "/noPermissions" });
      }
    },
    async getSignature(agentid, appid) {
      let url = window.location.href.split("#")[0];
      try {
        let data = {
          url: url,
          agentId: agentid,
        };
        console.log("data");
        console.log(data);
        let res = await getSignature(data);
        console.log(res);
        let result = JSON.parse(res);
        console.log(result);

        wx.agentConfig({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          corpid: appid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: Number(result.timestamp), // 必填，生成签名的时间戳
          nonceStr: String(result.nonce_str), // 必填，生成签名的随机串
          signature: String(result.signature), // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: ["onHistoryBack", "closeWindow", "onMenuShareAppMessage"], //必填，传入需要使用的接口名称
          success: function (res) {
            // alert("seccess")
            wx.onHistoryBack(function () {
              if (JSON.parse(sessionStorage.getItem("from")) == "/") {
                wx.closeWindow();
              } else {
                let agentid = JSON.parse(sessionStorage.getItem("agentid"));
                let appid = JSON.parse(sessionStorage.getItem("appid"));
                sessionStorage.clear();
                this.$router.push({
                  path: "/LoginCodes",
                  query: {
                    agentid: agentid,
                    appid: appid,
                  },
                });
              }
              return true;
            });
            wx.onMenuShareAppMessage({
              title: "舆情报告", // 分享标题
              desc: "随时查阅舆情报告，及时掌握关键信息。 ", // 分享描述
              link: window.location.href, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
              imgUrl:
                "http://inews5.xlmediawatch.com/reportFile/asslogjs/1047/lnewsImg.png", // 分享图标
              success: function () {
                // 用户确认分享后执行的回调函数
              },
              cancel: function () {
                // 用户取消分享后执行的回调函数
              },
            });
          },
          fail: function (res) {
            if (res.errMsg.indexOf("function not exist") > -1) {
              alert("版本过低请升级");
            }
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  async created() {
    let agentid = JSON.parse(sessionStorage.getItem("agentid"));
    let appid = JSON.parse(sessionStorage.getItem("appid"));
    console.log(window.location.href);
    console.log(this.$route.query.orgId);
    this.orgId = this.$route.query.orgId;
    await this.getLoginMsg(agentid, appid);
  },
};
</script>

<style scoped lang="less">
.userManagement_JH {
  width: 100%;
  height: 100%;
}
</style>
